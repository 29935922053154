@import '@wise/ui-constants';

.custom-support-details {
  padding: 20px;
  font-size: 17px;
  border: 1px solid var(--color-disabled);
  border-radius: 10px;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;

  &__link {
    text-align: center;
    padding-top: 10px;
    a {
      font-size: $font-size-normal;
    }
  }
}
