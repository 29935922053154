@import '../css/globals/constants.css';

.support-details {
  a {
    display: block;
    font-size: 12px;
    text-decoration: none;
  }
  &__title {
    color: var(--color-text-primary);
    text-decoration: none;
    text-align: center;
    font-size: 14px;
  }
  &__tel {
    text-decoration: none;

    .info-modal & {
      font-size: 18px;
      display: block;
    }
  }
  &__link {
    text-decoration: none;
    text-align: center;
    font-size: 14px;
  }
  &__hours {
    margin: 1em 0 0;

    span {
      color: var(--color-text-secondary);
      display: block;

      .popover-element & {
        font-size: 12px;
      }
    }
  }
}

.support-container {
  margin-bottom: 40px;
}
