@import '@wise/ui-constants';

:root {
  --site-content-max-width: 480px;
  --site-content-wide-max-width: 610px;
  --site-content-desktop-wide-max-width: 850px;

  --less-than-iphone6: 374px;

  --short-time-frame: 165ms;
  --medium-time-frame: 330ms;
  --large-time-frame: 495ms;

  --color-havelock-blue: #4395d5;
  --color-picton-blue: #5bb9e6;
  --color-border-weak: var(--color-disabled);

  --icon-important-color: var(--color-havelock-blue);
  --icon-important-color-hover: var(--color-picton-blue);

  --color-cost-table-border: #abb1b1;
  --color-sustainability-pick-background: rgba(0, 0, 0, 0.03);

  /* the following are parameters from dbk/ui-constants */
  --motion-transition-duration: 330ms;
  --motion-transition-timing: cubic-bezier(0.23, 1, 0.32, 1);
  --palette-primary-text-headline: #024; /* color.brand.platinum.platinumDarkBlue */

  --chart-size-mobile: 234px;
  --chart-size-tablet: 380px;
  --input-text-color: #111515;
}

@custom-media --med-is-desktop (width > 1024px);
@custom-media --med-is-not-desktop (width <= 1024px);

@custom-media --med-iphone-6 (width <= 375px);
@custom-media --med-mobile-max-and-tablet-max (767px <= width <= 1024px);

@custom-media --med-tablet-or-desktop (width >= 768px);
@custom-media --med-tablet-portrait-or-less (width <= 768px);

@custom-media --med-iphone-6-landscape-or-bigger (width >= 667px);
@custom-media --med-iphone-6-portrait (width < 667px);

@custom-media --med-is-mobile (width < 768px);
@custom-media --med-is-tablet-landscape-or-desktop (width >= 1024px);

@custom-media --med-is-iphone-6-or-bigger (width >= 375px);

@custom-media --med-is-desktop-device (min-device-width: 1025px);
@custom-media --med-is-smaller-than-iphone-6 (width < 375px);
@custom-media --med-is-iphone-5 (width <= 321px);
