@import '@wise/ui-constants/src/index.theme.js';

.errorbox {
  width: 100%;
  text-align: center;
  margin-top: var(--spacing-normal);

  .cloudy-icon {
    max-width: 145px;
    max-height: 115px;
    transform: scale(0.6);

    @media (min-width: $bp-tablet-min) {
      transform: scale(0.8);
    }
  }

  .support-details {
    max-width: 500px;
    margin: 0 auto;
    text-align: left;
    border: 1px solid var(--color-stroke-primary);
  }

  &__title {
    font-size: var(--font-size-xlarge);
    margin-top: var(--spacing-xsmall);
    margin-bottom: var(--spacing-large);

    @media (min-width: $bp-tablet-min) {
      font-size: var(--font-size-xlarge);
      margin-top: var(--spacing-xsmall);
    }
  }
}
